exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ar-js": () => import("./../../../src/pages/ar.js" /* webpackChunkName: "component---src-pages-ar-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-fr-js": () => import("./../../../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-ar-js": () => import("./../../../src/templates/page-ar.js" /* webpackChunkName: "component---src-templates-page-ar-js" */),
  "component---src-templates-page-en-js": () => import("./../../../src/templates/page-en.js" /* webpackChunkName: "component---src-templates-page-en-js" */),
  "component---src-templates-page-fr-js": () => import("./../../../src/templates/page-fr.js" /* webpackChunkName: "component---src-templates-page-fr-js" */)
}

